import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Info, MessageCircle, Check, X } from 'lucide-react';

function PlanosNutri() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const svgIcon = (
    <svg height="22" width="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#D1F7E0"></circle> {/* Fundo verde mais claro */}
      <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" className="text-green-600"></path> {/* Check em verde escuro */}
    </svg>
  );
  
  
  

  const features = [
    { icon: svgIcon, text: 'Dieta Totalmente Personalizada' },
    { icon: svgIcon, text: 'Baseada em seus alimentos' },
    { icon: svgIcon, text: 'Calorias ajustadas ' },
    { icon: svgIcon, text: 'Quantidade de cada alimentos' },
    { icon: svgIcon, text: 'Horário de cada Refeição' },
    { icon: svgIcon, text: 'Resumo sobre sua situação' },
  ];

  const featuresDietaConsulta = [
    { icon: svgIcon, text: 'Dieta Modificada pela Nutri' },
    { icon: svgIcon, text: 'Direito para ajustar sua dieta' },
    { icon: svgIcon, text: 'Variedade de opções' },
    { icon: svgIcon, text: 'Consulta feita pelo Whatsapp' },
    { icon: svgIcon, text: 'Resultados mais rápidos' },
    { icon: svgIcon, text: 'Dicas da Nutricionista' },
  ];

  const featurestreino = [
    { icon: svgIcon, text: 'Dieta Personalizada' },
    { icon: svgIcon, text: 'Treinos Personalizados' },
    { icon: svgIcon, text: 'Resumo sobre sua situação' },
    { icon: svgIcon, text: 'Opções para casa ou academia' },
    { icon: svgIcon, text: 'Com base suas preferências' },
    { icon: svgIcon, text: 'Calorias e intensidade ajustadas' },
  ];

  function ProductCard({ title, price, features, buttonText, link, topo, showHowItWorks = false }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <div className="bg-card text-card-foreground p-6 rounded-lg shadow-lg border border-gray-200 flex flex-col h-full">
        <div className="text-center flex-grow">
          <span className="bg-green-100 text-green-600 text-xs font-semibold px-2.5 py-0.5 rounded">{topo || 'Economize 20%'}</span>
          <div className="text-2xl mt-1 font-bold">{title}</div>
          <div className="text-xl font-semibold mt-2"><span className="text-4xl font-bold">{price}</span></div>
        </div>
        <ul className="mt-6 space-y-3 flex-grow">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <span className="icon">{feature.icon}</span>
              <span className="ml-2" style={{ color: '#4d4d4d', fontSize: '0.9rem' }}>{feature.text}</span>
            </li>
          ))}
        </ul>
        <div className="mt-6">
          <Link to={link} className="w-full py-2 rounded-lg text-center text-white block" style={{
            backgroundColor: '#17A34A',
            textDecoration: 'none'
          }}>
            {buttonText}
          </Link>
          {showHowItWorks && (
            <button
              onClick={() => setIsModalOpen(true)}
              className="mt-2 w-full py-2 rounded-lg text-center text-white flex items-center justify-center"
              style={{ backgroundColor: '#17A34A' }}
            >
              <Info className="w-4 h-4 mr-2" />
              Como Funciona
            </button>
          )}
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white p-6 rounded-lg w-full max-w-md relative">
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
              
              <div className="flex justify-center mb-4">
                <MessageCircle size={48} className="text-green-600" />
              </div>
       
              <ul className="space-y-6 mb-6">
                <li className="flex items-start">
                  <span className="text-gray-700 flex-grow">
                    Após a confirmação do pagamento, na área da sua dieta estará disponível um botão para marcar a consulta. 
                    Por meio dele, você poderá agendar a consulta via WhatsApp, e a nutricionista entrará em contato diretamente com você.
                  </span>
                </li>
              </ul>
              <button
                onClick={() => setIsModalOpen(false)}
                className="w-full py-2 rounded-lg text-center text-white flex items-center justify-center"
                style={{ backgroundColor: '#17A34A' }}
              >
                <Check className="mr-2" size={20} />
                Entendi
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="text-center my-4 px-4">
      <h1 className="text-2xl font-bold text-foreground mb-0">Escolha o Plano Ideal</h1>
      <p className="text-gray-600 text-sm mt-0 mb-2">Preços que cabem no seu bolso!</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
        <ProductCard 
          title="Sua Dieta + Treino 🏋️‍♂️" 
          price="R$ 11,99" 
          topo="Mais Vendido"
          features={featurestreino} 
          buttonText="Comprar Agora" 
          link="/dietatreino"
        />
        <ProductCard 
          title="Dieta para Emagrecer 🔥" 
          topo="Dieta focada em Emagrecimento"
          price="R$ 9,99" 
          features={features} 
          buttonText="Comprar Agora" 
          link="/emagrecer"
        />
        <ProductCard 
          title="Acompanhamento com a Nutricionista" 
          topo="O mais completo"
          price="R$ 29,99" 
          features={featuresDietaConsulta} 
          buttonText="Comprar Agora" 
          link="/dieta3meses"
          showHowItWorks={true}
        />  
        <ProductCard 
          title="Emagrecer + Massa 💪" 
          price="R$ 10,99" 
          topo="Para emagrecer e Definir"
          features={features} 
          buttonText="Comprar Agora" 
          link="/definicao"
        />
        <ProductCard 
          title="Ganho de Massa " 
          topo="Para ganho de massa"
          price="R$ 14,99" 
          features={features} 
          buttonText="Comprar Agora" 
          link="/massamuscular"
        />
        <ProductCard 
          title="Definição + Massa" 
          topo="Para Definição e ganho de massa"
          price="R$ 16,99" 
          features={features} 
          buttonText="Comprar Agora" 
          link="/definicaomassa"
        />
      </div>
    </div>
  );
}

export default PlanosNutri;

