import React, { useState, useEffect, useCallback } from "react"
import { UserAuth } from "../../context/AuthContext"
import { CreditCard } from "lucide-react"

function criarLinkPagamento(linkexterno, token) {
  return `${linkexterno}?utm_content=${token}&utm_source=${token}`
}

function PagamentoComponent(linkexterno) {
  const [linkPagamento, setLinkPagamento] = useState(null)
  const { user } = UserAuth()

  const updateLinkPagamento = useCallback(() => {
    if (user && user.uid) {
      const link = criarLinkPagamento(linkexterno.linkexterno, user.uid)
      setLinkPagamento(link)
    } else {
      setLinkPagamento(null)
    }
  }, [user, linkexterno.linkexterno])

  useEffect(() => {
    updateLinkPagamento()
  }, [updateLinkPagamento])

  if (!linkPagamento) {
    return <p>Carregando...</p>
  }

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="flex items-center justify-center my-4">
        <hr className="flex-grow border-gray-300" />
        <span className="px-3 text-gray-500 text-sm">Ou</span>
        <hr className="flex-grow border-gray-300" />
      </div>
      <a className="no-underline w-full block" href={linkPagamento}>
        <button className="w-full flex items-center justify-center bg-white text-black border-2 border-black px-4 py-3 rounded-lg shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50">
          <CreditCard className="w-5 h-5 mr-2 text-black" />
          <span className="font-medium">Cartão de crédito</span>
        </button>
      </a>
    </div>
  )
}

export default PagamentoComponent

