import React from 'react';
import { WhatsAppModal } from './WhatsappOptions';



const Suporte = () => {
 
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 px-4">
      <WhatsAppModal isOpen={true} onClose={false}/>
    </div>
  );
};

export default Suporte;