import React, { useEffect, useState } from 'react';
import './ListaCompras.css';
import generatePDF from './generatePDF';
import HamburgerMenuLista from './Menu/MenuLista';
import { UserAuth } from '../../context/AuthContext';



const ListaCompras = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [shoppingList, setShoppingList] = useState({ proteínas: [], carboidratos: [], açúcares: [], frutas: [] });
    const {retornaDieta} = UserAuth()
    const [items, setItems] = useState('')
    const [dieta, setDieta] = useState('')
    const [isGenerating, setIsGenerating] = useState(false)
    
    useEffect(() => {
        const fetchDieta = async () => {
          const dieta = await retornaDieta()
          setDieta(dieta)
          console.log(dieta)
        } 
        fetchDieta()
    },[retornaDieta])

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    const categories = {
        proteínas: [
          'frango', 'queijo', 'alcatra', 'ovo','ovos','peixe', 'requeijão', 'atum', 'peru', 
          'queijo cottage', 'carne bovina', 'presunto', 'salmão', 'camarão', 'tofu', 
          'soja', 'seitan', 'lombo', 'cordeiro', 'frutos do mar', 'patinho', 'merluza', 'tilápia', 'carne moida',
          'carne de porco', 'presunto', 'salmão'
        ],
        carboidratos: [
          'arroz', 'macarrão', 'pão', 'batata', 'tapioca', 'aveia', 'biscoito', 'mandioca', 
          'cuscuz', 'rap10', 'quinoa', 'milho', 'centeio', 'trigo', 'farinha', 'leguminosas', 
          'feijão', 'lentilhas', 'grão de bico', 'cereal', 'inhame', 'batata inglesa'
        ],
        açúcares: [
          "iogurte",
          'chocolate',
          "café",
          "leite",
          "suco",
          "suco natural"
                ],
        frutas: [
          'maçã', 'banana', 'pêra', 'abacaxi', 'laranja', 'tomate', 'manga', 'uva', 'melancia', 
          'figo', 'morango', 'kiwi'
        ]
    };

    const normalizeFood = (food) => {
      food = food.toLowerCase().replace(/s\b|,|\./g, '').trim();
      if (food.includes('carne') && !food.includes('porco')) {
          return 'carne - Patinho ou Alcatra';
      } else if (food.includes('arroz integral')) {
          return 'arroz';
      }else if (food.includes('sanduiche de peru')){
        return 'peito de peru'
      }else if (food.includes('sanduiche de frango')){
        return 'frango desfiado'
         }else if (food.includes('Rap10 com recheio de Frango')){
        return 'frango desfiado'
      } else if (food.includes('iogurte natural desnatado')) {
          return 'iogurte';
      } else if (food.includes('cereal integral')) {
          return 'cereal';
      } else if (food.includes('carne de porco')) {
          return 'carne de porco';
      } else if (food.includes('peito de frango')) {
          return 'frango';
      } else if (food.includes('whey protein')) {
          return 'whey protein';
      } else if (food.includes('ovos') || food.includes('ovo')) {
          return 'ovo';
      } else if (food.includes('requeijao')) {
          return 'requeijão';
      }
      return food;
  };
  
  const extractFoodItems = (text) => {
    const foodLists = { proteínas: new Set(), carboidratos: new Set(), açúcares: new Set(), frutas: new Set() };

    // Remove os números, asteriscos e espaços no final do texto
    text = text.replace(/[\d:]+[*]*\**$/, '').trim();

    // Expressão regular para capturar os itens de alimentos
    const matches = text.matchAll(/([^(\n]+)\s*(\(\d+g|\d+ml|\d+\s*cal\))?/g);

    for (const match of matches) {
        if (!match[1].toLowerCase().includes('total:')) {
            // Separa os itens por '+'
            let items = match[1].split('+').map(item => item.trim());
            items.forEach(item => {
                let foodComponent = item.split('(')[0].trim();
                foodComponent = normalizeFood(foodComponent);
                Object.keys(categories).forEach(category => {
                    categories[category].forEach(food => {
                        if (foodComponent.includes(food) && !foodLists[category].has(food)) {
                            foodLists[category].add(food);
                        }
                    });
                });
            });
        }
    } 
      return {
          proteínas: Array.from(foodLists.proteínas),
          carboidratos: Array.from(foodLists.carboidratos),
          açúcares: Array.from(foodLists.açúcares),
          frutas: Array.from(foodLists.frutas)
      };
  };
   
    
    const handleGenerateList = () => {
      setIsGenerating(true);
      setTimeout(() => {
        const items = extractFoodItems(dieta);
        setShoppingList(items);
        setItems(items)
        setIsGenerating(false);
      }, 1500);
  
    };

    const handleDownloadPdf = () => {
        generatePDF(shoppingList);
    };

    const getCategoryIcon = (category) => {
      switch (category) {
        case 'frutas':
          return '🍏';
        case 'açúcares':
          return '☕';
        case 'carboidratos':
          return '🍚';
        case 'proteínas':
          return '🍗';
        default:
          return '🛒';
      }
    };

    return (<div>
            <header className="app-header">

        <HamburgerMenuLista isOpen={isMenuOpen} toggleMenu={toggleMenu}></HamburgerMenuLista>
       
        <h1 className='mt-4'>🥗 Lista de Compras Nutritiva</h1>
        </header>
      <main className="app-main">
        <div className="action-bar flex flex-row">
          <button 
            onClick={handleGenerateList} 
            className={`btn btn-primary ${isGenerating ? 'loading' : ''}`}
            disabled={isGenerating}
          >
            {isGenerating ? 'Gerando...' : 'Gerar Lista'}
          </button>
          {items && (
          <button 
            onClick={handleDownloadPdf} 
            className="btn btn-secondary"
            disabled={!shoppingList}
          >
            Baixar PDF
          </button>
          )}
        </div>
        {items && (
          <div className="list-content">
            <h2>Itens Necessários para a Dieta</h2>
            <div className="category-container">
              {Object.entries(shoppingList).map(([category, items]) => (
                <div key={category} className="category-card">
                  <h3>
                    <span className="category-icon">{getCategoryIcon(category)}</span>
                    {category}
                  </h3>
                  <ul>
                    {items.map((item, index) => (
                      <li key={index}>
                        <span className="item-name">{item}</span>
                        <span className="item-action">+</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>
    </div>
      );      
  };
  
export default ListaCompras;
