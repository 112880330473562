
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Signin from "../components/Sign/Signin";
import Signup from "../components/Sign/SignUp/Signup";
import ProtectedRoute from "../routes/ProtectedRoute";
import Home from "../components/Home/Home";
import Termos from "../components/Termos/Termos";
import Suporte from "../components/SuporteFaleConosco/Suporte";
import LocalDieta from "../components/LocalDieta/LocalDieta";
import JejumIntermitente from "../components/Checkouts/Email/email";
import SobreMim from "../components/InfoUsuario/SobreMim";
import RedefinePassword from "../components/RedefinePassword/RedefinePassword";
import SubscriptionComponent from "../components/MercadoPago/SubscriptionComponent/Subscription.component";
import Agradecimentos from '../components/Kiwify/Agradecimento'
import Obrigado from '../components/Agradecimentos/Obrigado'
import PlanosNutri from '../components/Planos/PlanosNutri'
import Calorias from '../components/CaloriasConsumidas/Calorias';
import Atendimento from '../components/Atendimento/Atendimento';
import Consulta from '../components/Atendimento/Consulta';
import ProtectedAtendimento from './ProtectedAtendimento';
import ListaCompras from '../components/ListaCompra/ListaCompras';
import LoadComponent from '../components/LoadingComponent/LoadComponent'
import AtendimentoPersonalizado from '../components/Atendimento/PersoAtendimento/AtendimentoPersonalizado';
import ProtectedAtendimentoPersonalizado from './ProtectedAtendimentoPersonalizado';
import Business from '../components/Business/LadingPage'
import IndiqueNutriInteligente from '../components/IndiqueGanhe/IndiqueGanhe'
import CheckoutEmail from '../components/Checkouts/Email/email';
import CheckoutTreino from '../components/Checkouts/DietaTreino/DietaTreino';
import CheckoutDefinicao from '../components/Checkouts/Definicao/Definicao';
import CheckoutDefinicaoMassa from '../components/Checkouts/DefMassa/DefinicaoMassa';
import CheckoutAcompanhamento from '../components/Checkouts/Acompanhamento/DietaAcompanhamento';
import CheckoutAcompanhamento2 from '../components/Checkouts/Acompanhamento/AcompanhamentoNutri';
import CheckoutMassaMuscular from '../components/Checkouts/MassaMuscular/MassaMuscular';
import CheckoutEmagrecer from '../components/Checkouts/Emagrecer/Emagrecer';



function App() {

  return (
    <Routes>
      <Route path="/" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/redefinirsenha" element={<RedefinePassword />} />
      <Route path="/termos" element={<Termos />} />
      <Route path="/calorias" element={<Calorias />} />
      <Route path="/suporte" element={<Suporte />} />
      <Route path="/dieta" element={<ProtectedRoute><LocalDieta /></ProtectedRoute>} />
      <Route path="/sobre" element={<ProtectedRoute><SobreMim /></ProtectedRoute>} />
      <Route path="/teste" element={<ProtectedRoute><SubscriptionComponent /></ProtectedRoute>} />
      <Route path="/obrigado" element={<ProtectedRoute><Agradecimentos></Agradecimentos></ProtectedRoute>} />
      <Route path="/comprarealizada" element={<Obrigado />} />
      <Route path="/listacompras" element={<ProtectedRoute><ListaCompras/></ProtectedRoute>} />
      <Route path="/carregando" element={<ProtectedRoute><LoadComponent/></ProtectedRoute>} />
      <Route path="/Business" element={<Business/>} />
      <Route path="/IndiqueNutriInteligente" element={<IndiqueNutriInteligente/>} />
      <Route path="/planos" element={<PlanosNutri />}/>
      <Route path="/send_email" element={<CheckoutEmail/>}/>


     
      

      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
     

<Route
        path="/atendimento"
        element={
          <ProtectedRoute>
            <ProtectedAtendimento>
            <Atendimento />
            </ProtectedAtendimento>
          </ProtectedRoute>
        }
      />

<Route
        path="/atendimentopersonalizado"
        element={
          <ProtectedRoute>
            <ProtectedAtendimentoPersonalizado>
            <AtendimentoPersonalizado />
          </ProtectedAtendimentoPersonalizado>
          </ProtectedRoute>
        }
      />

<Route
        path="/consulta"
        element={
          <ProtectedRoute>
            <Consulta />
          </ProtectedRoute>
        }
      />
      <Route
      
        path="/emagrecer"
        element={
          <ProtectedRoute>
            <CheckoutEmagrecer/>
          </ProtectedRoute>
        }
      />
    
      
      <Route
        path="/massamuscular"
        element={
          <ProtectedRoute>
            <CheckoutMassaMuscular />
          </ProtectedRoute>
        }
      />
   
      <Route 
      path= "/JejumIntermitente"
      element= {
        <ProtectedRoute>
          <JejumIntermitente />
        </ProtectedRoute>
      }
      />

      <Route
        path="/dietatreino"
        element={
          <ProtectedRoute>
            <CheckoutTreino />
          </ProtectedRoute>
        }
      />
      
 
      <Route
        path="/definicao"
        element={
          <ProtectedRoute>
            <CheckoutDefinicao />
          </ProtectedRoute>
        }
      />
      <Route
        path="/definicaomassa"
        element={
          <ProtectedRoute>
            <CheckoutDefinicaoMassa />
          </ProtectedRoute>
        }
      />

      <Route
        path="/dieta3meses"
        element={
          <ProtectedRoute>
            <CheckoutAcompanhamento2 />
          </ProtectedRoute>
        }
      />
       <Route
        path="/dietaacompanhamento"
        element={
          <ProtectedRoute>
            <CheckoutAcompanhamento />
          </ProtectedRoute>
        }
      />

    </Routes>
  );
}

export default App;
