

import React, { useState } from 'react';
import Espere from '../../img/Holdon.jpeg';
import { useNavigate } from 'react-router-dom';
import { WhatsAppModal } from '../SuporteFaleConosco/WhatsappOptions';



export default function ErrorCard({ message, onClose })  {
  
  const navigate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false)
    
      const openModal = () => setIsModalOpen(true)
      const closeModal = () => setIsModalOpen(false)

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md mx-auto w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2">
        <div className="flex justify-between mb-4 items-center">
          <div className='w-16'></div>
          <div className="w-12 h-12 rounded-full border-2 border-white overflow-hidden mx-1">
            <img src={Espere} alt="Pessoa 1" className="w-full h-full object-cover" />
          </div>
          <div>
          <button
            onClick={()=> navigate('/home')}
            className="bg-[#17A34A] text-white text-sm hover:bg-[#17A34A]/80 px-2 py-1 rounded-full w-full flex justify-center items-center"
          >
            Voltar
          </button>
          </div>
        </div>
        <h2 className="text-full font-bold">Envie seu comprovante</h2>
        <p className="text-md text-gray-600 mt-1"><strong> Por favor</strong> envie seu email e comprovante no número abaixo <strong>para verificarmos e liberarmos sua dieta...</strong> </p>
        <div className="mt-4 space-y-2">
         
          <button
            onClick={openModal}
            className="bg-green-800 text-white hover:bg-[#17A34A]/80 px-3 py-2 rounded-full w-full flex justify-center items-center"
          >
            Fale conosco
          </button>
          <WhatsAppModal isOpen={isModalOpen} onClose={closeModal} />

        </div>
        
      </div>
    </div>
  );
};



