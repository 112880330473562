import { CalendarIcon } from 'lucide-react';
import React from 'react';

const DueDateCard = ({data}) => {
  // Esta data pode ser substituída por uma data dinâmica vinda de props ou de um estado
  function calculateDueDate(data) {
    // Converte a data 'dd-mm-aaaa' para o formato ISO 'aaaa-mm-dd'
    const [day, month, year] = data.split('-'); // Divide a data pelo separador '-'
    const formattedData = `${year}-${month}-${day}`; // Reordena para o formato ISO

    // Converte a string formatada para o objeto Date
    const dueDate = new Date(formattedData);

    // Adiciona 30 dias
    dueDate.setDate(dueDate.getDate() + 30);

    // Formata a data final no formato dd-mm-aaaa
    const formattedDate = dueDate.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-'); // Substitui barras por traços

    return formattedDate;
  }

  
  const finalDate = calculateDueDate(data);

  return (
    <div className="max-w-sm mx-auto my-1">
    <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 overflow-hidden">
      <div className="flex justify-center ">
        <div className="flex items-center">
          
          <div className=' flex items-center justify-center text-center'>
          <div className="bg-green-50 rounded-full p-3  m-0">
            <CalendarIcon className="h-5 w-5 text-green-600" />
            </div>
            <p className="  m-0 text-sm font-medium text-gray-500">Vencimento: </p>
            <p className=" m-0 text-xl font-semibold text-gray-900">{finalDate}</p>
          </div>
        </div>
      </div>
      <div className="h-1 w-full bg-gradient-to-r from-green-400 to-blue-500"></div>
    </div>
  </div>
  );
};

export default DueDateCard;