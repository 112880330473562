import React from "react";

export default function Termos() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 space-y-6">
      <h1 className="text-2xl font-bold mb-6 text-center">Termos e Condições de Uso da Plataforma Nutri Inteligente</h1>
      
      <Section title="1. Introdução">
        <p className="mb-4">
          A Nutri Inteligente é uma plataforma inovadora que combina o poder da inteligência artificial com o acompanhamento profissional de nutricionistas para oferecer planos nutricionais personalizados e ajustados às necessidades de cada usuário. Ao utilizar nossos serviços, você concorda com os termos e condições estabelecidos abaixo. Recomendamos fortemente que leia atentamente este documento, pois ele contém informações cruciais sobre os direitos, responsabilidades e limitações associadas ao uso da nossa plataforma.
        </p>
        <p className="mb-4">
          A nossa missão é fornecer um atendimento nutricional de qualidade, utilizando tecnologia avançada para criar planos eficazes e adaptados ao seu estilo de vida e objetivos. Estamos comprometidos em oferecer um serviço de excelência, mas é importante entender as diretrizes que regem o uso dos nossos serviços, garantindo que todas as partes envolvidas tenham clareza sobre os termos de uso e as expectativas.
        </p>
        <p className="mb-4">
          Por favor, tenha em mente que, ao acessar e utilizar a Nutri Inteligente, você está concordando com todos os aspectos descritos neste documento, incluindo as limitações de responsabilidade, os serviços oferecidos e as condições de cancelamento e reembolso. O uso contínuo da nossa plataforma implica na aceitação dessas condições, e qualquer alteração será comunicada aos usuários de forma clara e transparente.
        </p>
      </Section>

      <Section title="2. Natureza dos Planos">
        <p className="mb-4">
          A Nutri Inteligente oferece duas categorias de planos, projetados para atender a diferentes necessidades e preferências dos usuários. Ao escolher um plano, é essencial que você compreenda as características e limitações de cada um, para garantir que o serviço oferecido seja o mais adequado às suas expectativas.
        </p>
        
        <SubSection title="2.1 Planos sem Acompanhamento Profissional">
          <p className="mb-4">
            Esses planos são baseados exclusivamente em algoritmos de inteligência artificial desenvolvidos para criar sugestões nutricionais personalizadas. As recomendações geradas por esses planos são criadas a partir de um modelo predefinido que considera fatores como idade, sexo, peso, altura e objetivos de saúde, sem levar em conta condições médicas específicas ou interações medicamentosas.
          </p>
          <p className="mb-4">
            É importante destacar que os planos sem acompanhamento profissional não substituem consultas médicas. Eles não realizam diagnósticos médicos, não avaliam condições de saúde complexas e não podem ser usados como um substituto para a orientação de um profissional da saúde. Além disso, as sugestões feitas não consideram possíveis restrições alimentares devido a doenças pré-existentes ou interações com medicamentos.
          </p>
        </SubSection>

        <SubSection title="2.2 Planos com Acompanhamento Profissional">
          <p className="mb-4">
            Esses planos incluem a interação com nutricionistas registrados e certificados, que oferecem acompanhamento personalizado para ajustar o plano alimentar de acordo com as necessidades e restrições do usuário. Com a orientação profissional, o plano nutricional pode ser adaptado para condições de saúde específicas, como diabetes, hipertensão, alergias alimentares, entre outras, considerando interações medicamentosas e necessidades especiais de cada indivíduo.
          </p>
          <p className="mb-4">
            O acompanhamento de um nutricionista certificado visa garantir que as mudanças alimentares sejam seguras e eficazes, promovendo resultados sustentáveis e respeitando a saúde do usuário.
          </p>
        </SubSection>

        <SubSection title="2.3 Considerações Importantes">
          <ul className="list-disc pl-6 mb-4">
            <li className="text-black">Os planos sem acompanhamento profissional são recomendados para usuários que desejam uma sugestão básica de dieta, mas que não possuem condições médicas específicas ou não estão em tratamento que exija acompanhamento nutricional especializado.</li>
            <li className="text-black">Os planos com acompanhamento profissional são ideais para aqueles que buscam orientação contínua, têm condições de saúde específicas ou preferem um serviço mais individualizado e com suporte regular de um nutricionista.</li>
          </ul>
        </SubSection>
      </Section>

      <Section title="3. Responsabilidade do Usuário">
  <p className="mb-4">
    Ao utilizar a plataforma <strong>Nutri Inteligente</strong>, o USUÁRIO declara, para todos os fins de direito, que compreende e aceita as condições descritas neste termo, assumindo total responsabilidade pelas decisões tomadas com base nas informações fornecidas pela plataforma.
  </p>
  <p className="mb-4">
    <strong>Cláusula 3.1 – Veracidade das Informações:</strong> O USUÁRIO compromete-se a inserir informações completas, verdadeiras e precisas na plataforma. Dados incorretos, omissos ou incompletos poderão comprometer a eficácia das orientações e sugestões fornecidas, sendo de exclusiva responsabilidade do USUÁRIO os prejuízos daí decorrentes.
  </p>
  <p className="mb-4">
    <strong>Cláusula 3.2 – Consulta a Profissionais Qualificados:</strong> O USUÁRIO declara estar ciente de que a <strong>Nutri Inteligente</strong> possui caráter informativo e que, em nenhuma hipótese, substitui o acompanhamento de profissionais da saúde, como médicos e nutricionistas. O USUÁRIO compromete-se a consultar tais profissionais antes de realizar mudanças significativas na dieta ou na rotina alimentar.
  </p>
  <p className="mb-4">
    <strong>Cláusula 3.3 – Restrições Alimentares e Preferências:</strong> É de exclusiva responsabilidade do USUÁRIO verificar a compatibilidade das sugestões fornecidas pela plataforma com suas restrições alimentares, intolerâncias, alergias ou preferências pessoais.
  </p>
  <p className="mb-4">
    <strong>Cláusula 3.4 – Objetivos Realistas e Saudáveis:</strong> O USUÁRIO reconhece que o uso da plataforma deve ser realizado em conformidade com princípios de saúde e bem-estar, incluindo a adoção de hábitos equilibrados, como a prática de atividades físicas regulares e o descanso adequado.
  </p>
  <p className="mb-4">
    <strong>Cláusula 3.5 – Isenção de Responsabilidade:</strong> A <strong>Nutri Inteligente</strong> não se responsabiliza por eventuais danos diretos ou indiretos decorrentes do uso das informações fornecidas, sendo responsabilidade exclusiva do USUÁRIO assegurar-se da adequação das sugestões às suas condições individuais.
  </p>
  <p>
    Por meio deste termo, o USUÁRIO reconhece e aceita que todas as informações fornecidas pela plataforma possuem caráter meramente orientativo, cabendo-lhe a responsabilidade de consultar profissionais habilitados sempre que necessário.
  </p>
</Section>


<Section title="4. Limitações dos Planos sem Acompanhamento">
  <p className="mb-4">
    <strong>Cláusula 4.1 – Não Substituição de Profissionais de Saúde:</strong> Os planos oferecidos pela plataforma <strong>Nutri Inteligente</strong>, incluindo, mas não se limitando a <strong>Emagrecer</strong>, <strong>Ganho de Massa</strong>, <strong>Definição + Massa</strong>, <strong>Emagrecer + Massa </strong> e <strong>Dieta + Treino</strong>, não substituem, em nenhuma hipótese, o acompanhamento de profissionais de saúde, tais como nutricionistas ou médicos.
  </p>
  <p className="mb-4">
    <strong>Cláusula 4.2 – Limitações dos Planos:</strong> Os planos disponibilizados pela plataforma não realizam:
    <ul className="list-disc pl-6">
      <li className="text-black"> Diagnósticos de condições clínicas específicas;</li>
      <li className="text-black">Identificação de interações entre medicamentos e alimentos;</li>
      <li className="text-black">Avaliação de necessidades médicas individuais.</li>
    </ul>
  </p>
  <p className="mb-4">
    <strong>Cláusula 4.3 – Validação por Profissionais Qualificados:</strong> Recomenda-se expressamente que todas as orientações e sugestões fornecidas pelos planos da <strong>Nutri Inteligente</strong> sejam validadas por profissionais qualificados antes da adoção de mudanças significativas na dieta ou na rotina alimentar.
  </p>
  <p className="mb-4">
    <strong>Cláusula 4.4 – Sugestão de Acompanhamento Personalizado:</strong> Para maximizar a eficácia e segurança dos planos, sugere-se ao USUÁRIO a aquisição do acompanhamento com um nutricionista, que poderá oferecer orientações específicas, personalizadas e adaptadas às necessidades individuais do USUÁRIO.
  </p>
</Section>


      <Section title="5. Exclusividade dos Serviços com Acompanhamento">
        <p className="mb-4">
          O acompanhamento nutricional é exclusivo para usuários que adquirirem planos com essa funcionalidade. Consultas fora dos limites estabelecidos no plano não serão atendidas. O número de consultas e a forma de interação estão detalhados nas condições do plano contratado.
        </p>
      </Section>

      <Section title="6. Limitações de Responsabilidade">
        <p className="mb-4">
          Mesmo nos planos com acompanhamento profissional, o sucesso no alcance das metas depende da precisão e veracidade das informações fornecidas por você e do seu comprometimento em seguir o plano alimentar e as orientações recebidas. Resultados específicos não podem ser garantidos, uma vez que cada organismo reage de maneira única.
        </p>
      </Section>

      <Section title="7. Cancelamento e Reembolso">
        <p className="mb-4">
          Em conformidade com o Código de Defesa do Consumidor (CDC), você tem o direito de cancelar o plano e solicitar reembolso dentro de 7 dias após a contratação. As condições específicas de reembolso dependem se o plano inclui acompanhamento e se as consultas disponíveis foram utilizadas.
        </p>
      </Section>

      <Section title="8. Resolução de Conflitos">
        <p className="mb-4">
          Qualquer disputa relacionada ao uso da plataforma será regida pelas leis brasileiras, sendo o foro exclusivo da Comarca de Volta Redonda, no estado do Rio de Janeiro.
        </p>
      </Section>

      <Section title="9. Modificações nos Termos">
        <p className="mb-4">
          A Nutri Inteligente reserva-se o direito de atualizar ou modificar estes termos a qualquer momento. Recomendamos que revise esta página periodicamente para estar ciente de possíveis alterações. O uso contínuo da plataforma implica na aceitação dos termos mais recentes.
        </p>
      </Section>

      <Section title="10. Concordância com os Termos">
        <p className="mb-4">
          Ao utilizar a Nutri Inteligente, você declara ter lido, compreendido e concordado com estes termos e condições. Caso não concorde, pedimos que não utilize a plataforma.
        </p>
        <p className="mb-4">
          Em caso de dúvidas, entre em contato conosco pelos canais de suporte disponibilizados no site.
        </p>
      </Section>

      <Section title="11. Política de Privacidade e Proteção de Dados">
        <p className="mb-4">
          A Nutri Inteligente valoriza sua privacidade e se compromete a proteger seus dados pessoais. Todas as informações fornecidas durante o uso da plataforma serão tratadas de acordo com a legislação vigente, incluindo a Lei Geral de Proteção de Dados (LGPD). Seus dados serão utilizados apenas para fins relacionados à prestação do serviço, como personalização de planos, envio de notificações e melhorias na experiência do usuário. Para mais informações, consulte nossa Política de Privacidade.
        </p>
      </Section>

      <Section title="12. Uso Indevido da Plataforma">
        <p className="mb-4">
          É estritamente proibido:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li className="text-black">Utilizar a plataforma para finalidades ilícitas ou contrárias aos presentes Termos.</li>
          <li className="text-black">Compartilhar ou revender os serviços ou informações adquiridas através da Nutri Inteligente.</li>
          <li className="text-black">Inserir informações falsas ou enganosas no cadastro ou durante a utilização dos serviços.</li>
        </ul>
        <p className="mb-4">
          Qualquer uso indevido pode resultar no cancelamento imediato da conta, sem reembolso, além de possíveis medidas legais.
        </p>
      </Section>

      <Section title="13. Propriedade Intelectual">
        <p className="mb-4">
          Todo o conteúdo da Nutri Inteligente, incluindo textos, algoritmos, design, marca e materiais disponibilizados, é protegido por leis de propriedade intelectual. É proibido reproduzir, distribuir, modificar ou explorar comercialmente qualquer parte do conteúdo sem autorização prévia e expressa.
        </p>
      </Section>

      <Section title="14. Limitações Técnicas e Interrupções de Serviço">
        <p className="mb-4">
          A Nutri Inteligente não se responsabiliza por interrupções temporárias no serviço devido a manutenção, problemas técnicos ou fatores fora do controle da empresa, como falhas de internet. Nos comprometemos a informar previamente sobre manutenções programadas e a trabalhar para restabelecer o serviço o mais rápido possível.
        </p>
      </Section>

      <Section title="15. Restrições de Uso por Idade">
        <p className="mb-4">
          A plataforma é destinada exclusivamente a usuários maiores de 18 anos. Menores de idade só poderão utilizá-la com supervisão e consentimento de um responsável legal.
        </p>
      </Section>
    </div>
  );
}

function Section({ title, children }) {
  return (
    <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
      <div className="flex flex-col space-y-1.5 p-6">
        <h3 className="text-2xl font-semibold leading-none tracking-tight">{title}</h3>
      </div>
      <div className="p-6 pt-0">{children}</div>
    </div>
  );
}

function SubSection({ title, children }) {
  return (
    <div className="mt-4 mb-2">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      {children}
    </div>
  );
}

