import React from 'react';
import { jsPDF } from 'jspdf';
import RecomendaFrutas from '../../../../img/RecomendaFruta.png';
import { Info } from 'lucide-react';

export default function ListaSubstituicaoFrutas() {
    const downloadPdf = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const headerHeight = 60; 
        const lineHeight = 10; 
        doc.addImage(RecomendaFrutas, 'PNG', 0, 0, pageWidth, headerHeight);

        let yPos = headerHeight + 20;  
        const staticText = `
A lista de substituição é uma ferramenta útil para diversificar seu cardápio sem ter que repetir todos os dias os mesmos alimentos prescritos em sua dieta. Com essa variedade, você pode desfrutar de uma alimentação mais interessante e menos monótona. É importante, no entanto, aderir às quantidades especificadas para evitar ultrapassar o limite calórico diário estabelecido pelo seu plano alimentar.

Substituições de Frutas
Maçã: Pera, Pêssego, Nectarina, Ameixa, Apricot (damasco)

Dica: Maçãs e suas substituições são ótimas para lanches rápidos e podem ser adicionadas a saladas e sobremesas.
Banana: Manga, Mamão, Abacate, Caqui, Maracujá

Dica: Banana e suas substituições são ricas em potássio e são excelentes para smoothies ou como um lanche energético pré-treino.
Laranja: Tangerina, Limão siciliano, Pomelo, Grapefruit (toranja), Bergamota

Dica: Laranja e substituições são fontes ricas de vitamina C, perfeitas para sucos ou como parte de um café da manhã equilibrado.
Morango: Amora, Mirtilo (blueberry), Framboesa, Groselha, Cereja

Dica: Morangos e substituições são baixos em calorias e ricos em antioxidantes, ideais para sobremesas ou adicionados a iogurtes.
Abacaxi: Melão, Kiwi, Guava (goiaba), Carambola, Papaia

Dica: Abacaxi e substituições são excelentes para a digestão e adicionam um sabor tropical a saladas de frutas.
Uva: Jabuticaba, Kiwi, Mirtilo, Amora, Romã

Dica: Uvas e substituições são convenientes para lanches e podem ser uma adição doce a saladas ou pratos de queijo`;

        const splitText = doc.splitTextToSize(staticText, pageWidth - 20);
        splitText.forEach(line => {
            if (yPos + lineHeight > pageHeight) {
                doc.addPage();
                yPos = 10;
            }
            doc.text(line, 10, yPos);
            yPos += lineHeight;
        });

        doc.save("Lista Frutas - NutriInteligente.pdf");
    };

    return (
        <div 
            className="bg-white rounded-lg p-4 shadow-md hover:shadow-lg transition duration-300 flex flex-col items-center text-center w-full cursor-pointer relative"
            onClick={downloadPdf}
        >
            <div className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full mb-3">
                <span className="text-2xl">🍓</span>
            </div>
            <h3 className="text-lg font-semibold text-gray-700 mb-1">Substituição - Frutas</h3>
            <p className="text-sm text-gray-600 mb-3">Clique para acessar a lista de substituição de frutas</p>
            <div className="text-xs text-gray-400 flex items-center mb-2">
                <Info size={12} className="mr-1" />
                Download do PDF apenas no Google Chrome e Safari.
            </div>
            <div className="bg-green-200 text-green-800 px-3 py-1 rounded-full text-sm font-semibold mt-2 flex items-center justify-center">
                ✅ Disponível
            </div>
        </div>
    );
}

