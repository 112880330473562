import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function SimpleDietPrompt({ onClose }) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center min-h-screen bg-white p-4">
      <div className="w-full max-w-md">
        <div className="text-center">
          <div className="mx-auto mb-6 rounded-full bg-green-100 p-3 w-20 h-20 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-12 h-12 text-green-600"
            >
              <path d="M12 2L2 7l10 5 10-5-10-5z" />
              <path d="M2 17l10 5 10-5" />
              <path d="M2 12l10 5 10-5" />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Sua Dieta Está Pronta!</h2>
          <p className="text-gray-600 mb-8">
            <span className="font-semibold">Você tem uma dieta salva.</span>
            <br />
            Deseja visualizá-la ou ir para a página principal?
          </p>
          <div className="space-y-4">
            <button
              className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-4 rounded-full transition duration-300"
              onClick={() => navigate('/dieta')}
            >
              Ver Minha Dieta
            </button>
            <button
              className="w-full bg-white hover:bg-gray-100 text-green-600 font-semibold py-3 px-4 border border-green-600 rounded-full transition duration-300 flex items-center justify-center"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4 mr-2"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                <polyline points="9 22 9 12 15 12 15 22" />
              </svg>
              Montar Outra Dieta
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}